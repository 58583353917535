import styled from "styled-components"

import { STYLE } from "style"
import { COLOR_MAIN_DARK, COLOR_WHITE } from "style/colors"

export const Caption = styled.p`
	${STYLE.TEXT.MOBILE.CAPTION}
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: left;
	color: ${COLOR_WHITE};
	background-color: ${COLOR_MAIN_DARK}cc; /* cc == 80% */
	padding: 8px 12px;
	margin: 0;
	@media screen and (min-width: 800px) {
		${STYLE.TEXT.DESKTOP.CAPTION}
		padding: 12px 24px;
	}
`
