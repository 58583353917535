import { useEffect, useState } from "react"

/*
	Courtesy of Josh Comeau
	Necessary because window is undefined 
	Unsafe in certain situations
*/

const QUERY = "(prefers-reduced-motion: no-preference)"
const isRenderingOnServer = typeof window === "undefined"
const getInitialState = () => {
    return isRenderingOnServer ? true : !window.matchMedia(QUERY).matches
}
export function usePrefersReducedMotionSSR() {
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(
        getInitialState
    )
    useEffect(() => {
        const mediaQueryList = window.matchMedia(QUERY)
        const listener = event => {
            setPrefersReducedMotion(!event.matches)
        }
        mediaQueryList.addListener(listener)
        return () => {
            mediaQueryList.removeListener(listener)
        }
    }, [])
    return prefersReducedMotion
}
