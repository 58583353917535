import React from "react"
import styled from "styled-components"
import { Carousel as BaseCarousel } from "react-responsive-carousel"

import "react-responsive-carousel/lib/styles/carousel.min.css"

import { usePrefersReducedMotionSSR } from "hooks/usePrefersReducedMotionSSR"

import { COLOR_MAIN, COLOR_MAIN_DARK, COLOR_MAIN_LIGHT } from "style/colors"
import { utility } from "../../style/style-utils"

const CarouselComponent = styled(BaseCarousel)`
	.slide {
		background: transparent;
		max-height: 70vh;
		> * {
			height: 100%;
		}
	}
	.carousel {
		overflow: visible;
	}
	.carousel-slider {
		display: flex;
		flex-direction: column-reverse;
		.slide {
			display: flex;
			flex-direction: column;
		}
		.control-arrow {
			opacity: 1;
		}
		.control-prev.control-arrow:before {
			border-right-color: ${COLOR_MAIN};
		}
		.control-next.control-arrow:before {
			border-left-color: ${COLOR_MAIN};
		}
		.control-arrow:hover {
			background-color: ${COLOR_MAIN}aa;
		}
		.control-dots {
			position: relative;
			margin: 8px -8px;
			width: auto;
			display: flex;
			display: grid;
			grid-template-columns: repeat(9, minmax(0, 100px));
		}
		.dot {
			opacity: 1;
			/*background-color: ${COLOR_MAIN_LIGHT};
			width: 100px;
			height: 10px;*/
			border-radius: 0;
			&.selected {
				background-color: ${COLOR_MAIN_DARK};
			}
			&:after {
				content: "";
				background-color: ${COLOR_MAIN_LIGHT};
				width: 100px;
				height: 10px;
			}
		}
	}
`

const DotWrap = styled.li`
	list-style: none;
	display: block;
`

const Dot = styled.button`
	${utility.button}
	color: ${props => (props.selected ? COLOR_MAIN_DARK : COLOR_MAIN_LIGHT)};
	background: transparent;
	border: 0;
	padding: 8px;
	width: 100%;
	&:focus {
		outline: 2px solid currentColor;
	}
	&:focus:not(:focus-visible) {
		outline: none;
	}
	&:focus-visible {
		outline: 2px solid currentColor;
	}
`

const Square = styled.div`
	background-color: ${props =>
		props.selected ? COLOR_MAIN_DARK : COLOR_MAIN_LIGHT};
	/*width: 100px;
	max-width: 15vw;*/
	width: 100%;
	height: 10px;
`

export const Carousel = ({ children }) => {
	const reducedMotion = usePrefersReducedMotionSSR()
	return (
		<CarouselComponent
			autoPlay={!reducedMotion}
			infiniteLoop
			showThumbs={false}
			showStatus={false}
			renderIndicator={(
				clickHandler = () => {},
				isSelected,
				index,
				label
			) => (
				<DotWrap
					onClick={clickHandler}
					selected={isSelected}
					//tabIndex="0"
					aria-label={label}
					value={index}
				>
					<Dot selected={isSelected} disabled={isSelected} aria-label={"Knapp for " + label + " " + index}>
						<Square selected={isSelected} />
					</Dot>
				</DotWrap>
			)}
			/*  ||| this is nice, but it only checks height of the image, not the whole box, which is problematic
                vvv when we have a non-absolute legend. Would have to tweak the actual package to fix this, unfortunately.
            */
			dynamicHeight={true}
			labels={{
				leftArrow: "Gå til forrige bilde",
				rightArrow: "Gå til neste bilde",
				item: "Bilde",
			}}
		>
			{children}
		</CarouselComponent>
	)
}
