import styled, { css } from "styled-components"

import { STYLE } from "style"
import { COLOR_WHITE } from "style/colors"

export const TitleMain = styled.h1`
	display: block;
	${STYLE.TEXT.MOBILE.TITLE_MAIN}
	color: ${props => props.color || COLOR_WHITE};
	${props =>
		props.center &&
		css`
			text-align: center;
		`}
	${props =>
		props.nomargin &&
		css`
			margin: 0;
		`}
    ${props =>
		props.mb &&
		css`
			margin-bottom: ${props.mb};
		`}
    ${props =>
		props.bold &&
		css`
			font-weight: bold;
		`}
    ${props =>
		props.left &&
		css`
			text-align: left;
		`}
    ${props =>
		props.center &&
		css`
			text-align: center;
		`}
    ${props =>
		props.right &&
		css`
			text-align: right;
		`}
    @media screen and (min-width: 800px) {
		${STYLE.TEXT.DESKTOP.TITLE_MAIN}
		${props =>
			(props.center || props.dCenter) &&
			css`
				text-align: center;
			`};
		${props =>
			(props.left || props.dLeft) &&
			css`
				text-align: left;
			`};
		${props =>
			(props.right || props.dRight) &&
			css`
				text-align: left;
			`};
		${props =>
			(props.bold || props.dBold) &&
			css`
				font-weight: bold;
			`}
	}
`
